.activities-component {
  display: grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter activities-component__body right-gutter"
          "left-gutter activities-component__items right-gutter";
  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;
  padding: 1rem 0 3rem;

  &__body {
    padding-bottom: 3rem;
    text-align: center;

    grid-area: activities-component__body;
    -ms-grid-column: 2;
    -ms-grid-row: 1;
    -ms-grid-column-span: 1;
  }

  &__items {
    grid-area: activities-component__items;

    -ms-grid-column: 2;
    -ms-grid-row: 2;

    //-ms-grid-columns: auto;
    //-ms-grid-rows: auto 1.875rem auto 1.875rem auto 1.875rem auto 1.875rem;

    .block-component {
      display: flex;
      align-items: flex-end;
      margin-bottom: 1.875rem;
      height: auto !important;
      min-height: 270px;
    }
  }
}

@include media-breakpoint-up(sm) {
  .activities-component {
    &__items {
      display: grid;
      grid-gap: 1.875rem;
      //grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      //grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

      display: -ms-grid;
      -ms-grid-columns: 1fr 1.875rem 1fr;
      -ms-grid-rows: auto 1.875rem auto 1.875rem auto 1.875rem auto 1.875rem;

      .block-component {
        display: grid;
        min-height: 200px;
        margin-bottom: 0;

        &:nth-child(1) {
          grid-row: 1 / span 4;
          grid-column: 1 / span 6;

          -ms-grid-row: 1;
          -ms-grid-row-span: 1;

          -ms-grid-column: 1;
          -ms-grid-column-span: 1;
        }

        &:nth-child(2) {
          grid-row: 1 / span 4;
          grid-column: 7 / span 6;

          -ms-grid-row: 1;
          -ms-grid-row-span: 1;

          -ms-grid-column: 3;
          -ms-grid-column-span: 1;
        }

        &:nth-child(3) {
          grid-row: 5 / span 4;
          grid-column: 1 / span 6;

          -ms-grid-row: 3;
          -ms-grid-row-span: 1;

          -ms-grid-column: 1;
          -ms-grid-column-span: 1;
        }

        &:nth-child(4) {
          grid-row: 5 / span 4;
          grid-column: 7 / span 6;

          -ms-grid-row: 3;
          -ms-grid-row-span: 1;

          -ms-grid-column: 3;
          -ms-grid-column-span: 1;
        }

        &:nth-child(5) {
          grid-row: 9 / span 4;
          grid-column: 1 / span 6;

          -ms-grid-row: 5;
          -ms-grid-row-span: 1;

          -ms-grid-column: 1;
          -ms-grid-column-span: 1;
        }

        &:nth-child(6) {
          grid-row: 9 / span 4;
          grid-column: 7 / span 6;

          -ms-grid-row: 5;
          -ms-grid-row-span: 1;

          -ms-grid-column: 3;
          -ms-grid-column-span: 1;
        }

        &:nth-child(7) {
          grid-row: 13 / span 4;
          grid-column: 1 / span 6;

          -ms-grid-row: 7;
          -ms-grid-row-span: 1;

          -ms-grid-column: 1;
          -ms-grid-column-span: 1;
        }

        &:nth-child(8) {
          grid-row: 13 / span 4;
          grid-column: 7 / span 6;

          -ms-grid-row: 7;
          -ms-grid-row-span: 1;

          -ms-grid-column: 3;
          -ms-grid-column-span: 1;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .activities-component {
    padding: 5rem 0 9rem;

    &__items {

      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

      -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr;
      -ms-grid-rows: 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr;

      .block-component {
        min-height: 12vw;

        &:nth-child(1) {
          grid-row: 1 / span 4;
          grid-column: 1 / span 4;

          -ms-grid-row: 1;
          -ms-grid-row-span: 7;

          -ms-grid-column: 1;
          -ms-grid-column-span: 7;
        }

        &:nth-child(2) {
          grid-row: 1 / span 4;
          grid-column: 5 / span 4;

          -ms-grid-row: 1;
          -ms-grid-row-span: 7;

          -ms-grid-column: 9;
          -ms-grid-column-span: 7;
        }

        &:nth-child(3) {
          grid-row: 1 / span 4;
          grid-column: 9 / span 4;

          -ms-grid-row: 1;
          -ms-grid-row-span: 7;

          -ms-grid-column: 17;
          -ms-grid-column-span: 7;
        }

        &:nth-child(4) {
          grid-row: 5 / span 6;
          grid-column: 1 / span 6;

          -ms-grid-row: 9;
          -ms-grid-row-span: 11;

          -ms-grid-column: 1;
          -ms-grid-column-span: 11;
        }

        &:nth-child(5) {
          grid-row: 5 / span 3;
          grid-column: 7 / span 3;

          -ms-grid-row: 9;
          -ms-grid-row-span: 5;

          -ms-grid-column: 13;
          -ms-grid-column-span: 5;

          .block-component__title {
            display: none;
          }
        }

        &:nth-child(6) {
          grid-row: 5 / span 3;
          grid-column: 10 / span 3;

          -ms-grid-row: 9;
          -ms-grid-row-span: 5;

          -ms-grid-column: 19;
          -ms-grid-column-span: 5;

          .block-component__title {
            display: none;
          }
        }

        &:nth-child(7) {
          grid-row: 8 / span 3;
          grid-column: 7 / span 3;

          -ms-grid-row: 15;
          -ms-grid-row-span: 5;

          -ms-grid-column: 13;
          -ms-grid-column-span: 5;

          .block-component__title {
            display: none;
          }
        }

        &:nth-child(8) {
          grid-row: 8 / span 3;
          grid-column: 10 / span 3;

          -ms-grid-row: 15;
          -ms-grid-row-span: 5;

          -ms-grid-column: 19;
          -ms-grid-column-span: 5;

          .block-component__title {
            display: none;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xxxl) {
  .activities-component {
    grid-template-columns: 2fr 12fr 2fr;
    -ms-grid-columns: 2fr 12fr 2fr;
  }
}