@include media-breakpoint-up(md) {
  .two-c-layout {
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-column-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 6fr 1.875rem 6fr;

    > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }

      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }

      &:nth-of-type(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }

      &:nth-of-type(4) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }

      &:nth-of-type(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }

      &:nth-of-type(6) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
    }
  }

}