//custom settings bootstrap components
@import "breadcrumb";
@import "pagination";

%dagny-pro {
  font-family: ff-dagny-web-pro, sans-serif;
}
//font-weights
//regular: 400
//bold: 700

%advent-pro {
  font-family: 'Advent Pro', sans-serif;
}
//regular: 400

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1600px,
        xxxl: 1800px
);

// Modals

// Padding applied to the modal body
//$modal-inner-padding:               1rem !default;
//
//$modal-dialog-margin:               .5rem !default;
//$modal-dialog-margin-y-sm-up:       1.75rem !default;
//
//$modal-title-line-height:           $line-height-base !default;
//
//$modal-content-bg:                  $white !default;
//$modal-content-border-color:        rgba($black, .2) !default;
//$modal-content-border-width:        $border-width !default;
//$modal-content-border-radius:       $border-radius-lg !default;
//$modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5) !default;
//$modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5) !default;

$modal-backdrop-bg:                 rgba($grey-dark, 0.95);
$modal-backdrop-opacity:            1;
//$modal-header-border-color:         $gray-200 !default;
//$modal-footer-border-color:         $modal-header-border-color !default;
//$modal-header-border-width:         $modal-content-border-width !default;
//$modal-footer-border-width:         $modal-header-border-width !default;
//$modal-header-padding:              1rem !default;
//
//$modal-lg:                          800px !default;
//$modal-md:                          500px !default;
//$modal-sm:                          300px !default;
//
//$modal-transition:                  transform .3s ease-out !default;