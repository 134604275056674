body {
  @extend %dagny-pro;
  @include fluid-type($min_width, $max_width, (20px/1.2), 20px, 1.4/1.2, 1.4);
  color: $body-color;
}

h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  margin-top: 0;
  margin-bottom: 0.25rem;
}

p {
  margin-top: 0;
  margin-bottom: 1.75rem;
  &.lead {
    @include fluid-type($min_width, $max_width, (20px/1.2), 20px, 1.7/1.2, 1.7);
    font-weight: 800;
    text-transform: uppercase;
  }
}

h1,.h1 {
  margin-bottom: 1.5rem;
  @extend %advent-pro;
  @include fluid-type($min_width, $max_width, (60px/1.6), 60px, 0.9, 0.9);
  font-weight: normal;
  text-transform: uppercase;
}

h2,.h2 {
  margin-bottom: 1rem;
  @extend %advent-pro;
  @include fluid-type($min_width, $max_width, (50px/1.6), 50px, 1.1, 1.1);
  font-weight: normal;
  text-transform: uppercase;
}

h3,.h3 {
  @extend %advent-pro;
  @include fluid-type($min_width, $max_width, (40px/1.6), 40px, 1.4, 1.4);
  font-weight: normal;
  text-transform: uppercase;
}

h4,.h4 {
  @extend %advent-pro;
  @include fluid-type($min_width, $max_width, (30px/1.6), 30px, 1.85, 1.85);
  font-weight: normal;
  text-transform: uppercase;
}

h5,.h5 {
  @include fluid-type($min_width, $max_width, (24px/1.6), 24px, 1.1, 1.1);
  font-weight: bold;
  text-transform: uppercase;
}

h6,.h6 {
  @include fluid-type($min_width, $max_width, (20px/1.6), 20px, 1.85, 1.85);
  font-weight: bold;
  text-transform: uppercase;
}