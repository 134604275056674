.nav-container {
  position: fixed;
  background-color: $grey-dark;
  display:grid;
  grid-template-columns: 1fr 12fr 3fr;
  grid-template-areas:
          "left-gutter navbar right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 3fr;
  width: 100%;
  height: 48px;
  z-index: 9;
  transition: 0.3s;

  .navbar {
    grid-column: navbar;
    -ms-grid-column: 2;
    display:grid;

    grid-template-areas: "brandlogo searchbox searchbutton";
    grid-template-columns: auto auto auto;

    //justify-content: flex-start;
    align-items: center;

    display: -ms-grid;
    -ms-grid-columns: 1fr auto;
    position: relative;
    z-index: 1;

    .navbar-brand {
      display: inline-block;
      margin: 0.5rem 0;
      font-size: 0;
      line-height: 0;
      z-index: 1;
      grid-column: brandlogo;
      -ms-grid-column: 1;

      img {
        margin-right: 1.25rem;
        max-width: 109px;
      }
    }
    .nav {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
ul.nav-desktop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  padding: 2rem 0;
  margin: 0;
  text-align: left;
  z-index: 99;
  column-count: 3;
  transform: translateY(-100%);
  transition: .7s cubic-bezier(.49,1,.22,1) 0s;

  > li {
    width: 33.33%;
    text-align: center;
    > a {
      position: relative;
      display: inline-block;
      @extend %advent-pro;
      @include fluid-type($min_width, $max_width, (30px/1.6), 30px, 1.85, 1.85);
      font-weight: normal;
      text-transform: uppercase;
      color: $body-color;
      text-decoration: none;
      transition: 0.3s;
    }

    &.has-subitems {
      position: relative;
      margin-bottom: 2rem;

      &:after {
        content: "~";
        display: block;
        color: $white;
        font-size: 30px;
        font-weight: 700;
        line-height: 0.5;
      }
      a {
        color: $body-color;
        transition: 0.3s;
        &:after {
          content: "\E001";
          position: absolute;
          top: calc(50% - 5px);
          margin-left: 0.875rem;
          font-family: "visitheerde-icons";
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          @include fluid-type($min_width, $max_width, (10px/1.25), 10px, 1, 1);
          font-style: normal;
          font-variant: normal;
          font-weight: normal;
          /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
          text-decoration: none;
          text-transform: none;
          opacity: 0;
          transition: .35s cubic-bezier(.49,1,.22,1) .7ms;
        }
        &:hover {
          &:after {
            opacity: 1;
            transform: translateX(0.5rem);
          }
        }

      }
      .custom-arrow {
        display: none;
      }
    }

    > .submenu {
      text-align: center;
      transition: 0.3s;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        &.submenu-items {
          min-width: 200px;

          @include fluid-type($min_width, $max_width, (20px/1.25), 20px, 1, 1);

          > li {
            position: relative;
            display: block;
            padding: 0.75rem 0;
            break-inside: avoid;

            a {
              position: relative;
              color: $white;
              text-decoration: none;

            }
          }
        }
      }
    }

    //active item
    &.active {
      > a {
        //color: $primary;
      }
    }

    //open submenu
    &:hover {
      > a:not(.btn) {
        //color: $primary;
      }

      .submenu {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

body.mega-menu-active {
  overflow: hidden;
  #page-wrapper {
    &:before {
      visibility: visible;
      opacity: 1;
    }

    ul.nav-desktop {
      transform: translateY(0);
      transition: .7s cubic-bezier(.49,1,.22,1) .35s;
      overflow: auto;
      height: 100%;

      background-image: url("../images/bg-mega-menu.png");
      background-color: $primary;
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 40%;
      right: 0;
      top: 0;
      width: 100%;

      &:after {
        background: transparent;
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: 40%;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform: rotate(0);
        transition: .35s cubic-bezier(.49,1,.22,1) 0s;
      }
    }
  }
}

#nav-main {
  grid-column: nav;
  -ms-grid-column: 1;
}
#search {
  grid-column: searchbutton;
  -ms-grid-column: 2;
  display: -ms-flexbox;
  align-items: center;

  @extend %advent-pro;
  @include fluid-type($min_width, $max_width, (22px/1.25), 22px, 1, 1);
  color: $white;
  font-weight: bold;
  text-transform: uppercase;
  text-align: right;

  i {
    display: inline-block;
    vertical-align: middle;
    width: 3rem;
  }

  a {
    color: $white;
    line-height: 0;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      //color: $primary;
    }
  }
}

@include media-breakpoint-up(sm) {
  .nav-container {
    .navbar {
      .navbar-brand {
        img {
          max-width: 122px;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .nav-container {
    //grid-template-columns: 1fr 12fr 2fr;
    //-ms-grid-columns: 1fr 12fr 2fr;
  }
}

@include media-breakpoint-up(lg) {
  .nav-container {
    position: absolute;
    background-color: transparent;
    grid-template-areas:
            "navbar navbar navbar";
    padding-left: 3rem;
    padding-right: 8rem;
    .navbar {
      .navbar-brand {
        margin: 1.5rem 0;
        img {
          max-width: 255px;
        }
      }
    }
  }

  ul.nav-desktop, #nav-secundary {
    > li {
      &.has-subitems {
        position: relative;
        > a {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }

  ul.nav-desktop {
    padding-left: 2rem;
    padding-right: 2rem;

    &:after {
      content: "";
      background: $primary;
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 120px;
      z-index: -1;
      transition: .35s cubic-bezier(.49,1,.22,1) .35s;
      transform: rotate(45deg);
      transform-origin: bottom right;
    }

    > li {
      padding: 0.3125rem 1.5rem;
    }
  }

  #search {
    padding: 0.3125rem 1.5rem;
    a {
      outline: none;
    }
    a:hover, a:focus {
      color: $primary;
      outline: none;
    }
  }
}

@include media-breakpoint-up(xl) {
  .nav-container {
    .navbar {
      .navbar-brand {
        margin: 3rem 0;
        img {
          max-width: 355px;
        }
      }
    }
  }
}
@include media-breakpoint-up(xxl) {
  ul.nav-desktop {
    &:after {
      top: 180px;
    }
  }
}