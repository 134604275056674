body {
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;
}

table, .table {
  border: none;
  border-spacing: 0;

  th, td {
    border: none;
  }
}

.text-red {
  color: $red;
}
blockquote {
  margin: 1rem;
  display: flex;
  text-align: center;

  &:before, &:after {
    margin: 0 0.5rem;
  }
  &:before {
    content: "“";
    @include fluid-type($min_width, $max_width, (32px/1.6), 32px, 1.1, 1.1);
    align-self: flex-start;
  }
  &:after {
    content: "”";
    @include fluid-type($min_width, $max_width, (32px/1.6), 32px, 0.5, 0.5);
    align-self: flex-end;
  }
}


#timeline-header {
  font-size: 26px;
}

.bg-grey {
  background-color: $grey;
}

.bg-grey-dark {
background-color: $grey-dark;
}

.cancelled {
  text-decoration: line-through;
}

.past {
  background: #eee;
}

.text-center {
  text-align: center;
}

.site-content {
  &__date {
    background-color: $grey-dark;
    display: inline-block;
    padding: 0.25rem 0.5rem;
    margin-bottom: 2rem;
    align-self: flex-start;
    @include fluid-type($min_width, $max_width, (24px/1.6), 24px, 1.1, 1.1);
    font-variant-numeric: lining-nums;
    -moz-font-feature-settings: "lnum";
    -webkit-font-feature-settings: "lnum";
    font-feature-settings: "lnum";

    color: $white;
    text-transform: uppercase;
  }

  a {
    color: $primary;
    text-decoration: none;
    border-bottom: 1px solid;
  }

  ul {
    padding-left: 3.25rem;
    margin-top: 0;
    margin-bottom: 1.75rem;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 0.65rem;
      &:before {
        position: absolute;
        @extend .visitheerde-icons;
        @extend .visitheerde-icons-wave;
        @include fluid-type($min_width, $max_width, (4px/1.2), 4px, 7/1.2, 7);
        margin-left: -1.25rem;
        //font-size: 4px;
      }
    }
  }
}

.main_element {
  font-weight: bolder;
  color: #FFFFFF !important;
  background: #0196a3;
  border-color: #0196a3 !important;
}

#page-wrapper {
  transition: 0.3s;
  &:before {
    content: "";
    background-color: rgba($grey-dark, 0.95);
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }
}

.timeline {
  list-style: none;
  padding: 10px 0 10px;
  position: relative;
  width: 420px;

  &:before {
    background-color: #eee;
    bottom: 0;
    content: " ";
    left: 50px;
    margin-left: -1.5px;
    position: absolute;
    top: 0;
    width: 3px;
  }

  > li {
    margin-bottom: 10px;
    position: relative;

    &:before,
    &:after {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }

    > .timeline-panel {
      border-radius: 2px;
      border: 1px solid #d4d4d4;
      box-shadow: 0 1px 2px rgba(100, 100, 100, 0.2);
      margin-left: 100px;
      padding: 20px;
      position: relative;

      .timeline-heading {
        .timeline-panel-controls {
          position: absolute;
          right: 8px;
          top: 5px;

          .timestamp {
            display: inline-block;
          }

          .controls {
            display: inline-block;
            padding-right: 5px;
            border-right: 1px solid #aaa;

            a {
              color: #999;
              font-size: 11px;
              padding: 0 5px;

              &:hover {
                color: #333;
                text-decoration: none;
                cursor: pointer;
              }
            }
          }

          .controls + .timestamp {
            padding-left: 5px;
          }
        }
      }
    }

    .timeline-badge {
      background-color: #999;
      border-radius: 50%;
      color: #fff;
      font-size: 1.4em;
      height: 50px;
      left: 50px;
      line-height: 52px;
      margin-left: -25px;
      position: absolute;
      text-align: center;
      top: 16px;
      width: 50px;
      z-index: 100;
    }

    .timeline-badge + .timeline-panel {
      &:before {
        border-bottom: 15px solid transparent;
        border-left: 0 solid #ccc;
        border-right: 15px solid #ccc;
        border-top: 15px solid transparent;
        content: " ";
        display: inline-block;
        position: absolute;
        left: -15px;
        right: auto;
        top: 26px;
      }

      &:after {
        border-bottom: 14px solid transparent;
        border-left: 0 solid #fff;
        border-right: 14px solid #fff;
        border-top: 14px solid transparent;
        content: " ";
        display: inline-block;
        position: absolute;
        left: -14px;
        right: auto;
        top: 27px;
      }
    }
  }
}

.timeline-badge {
  &.primary {
    background-color: #2e6da4 !important;
  }

  &.success {
    background-color: #0196a3 !important;
  }

  &.warning {
    background-color: #000000 !important;
  }

  &.danger {
    background-color: #d9534f !important;
  }

  &.info {
    background-color: #5bc0de !important;
  }

  &.planning {
    background-color: #00629c !important;
  }
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body {
  > p,
  > ul {
    margin-bottom: 0;
  }

  > p + p {
    margin-top: 5px;
  }
}

.sitemap {
  > ul {
    column-count: 1;
    ul {
      margin-top: 1rem;
    }

    li {
      break-inside: avoid;
    }
  }
}

.copy {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #aaa;
  font-size: 11px;
  > * { color: #444; }
}

ul.list--default, ul.list {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.list--check {
  padding-left: 1.25rem;
  @include fluid-type($min_width, $max_width, (14px/1.25), 14px, (1.6/1.25), 1.6);
  font-weight: bold;
  text-transform: uppercase;
  li {
    position: relative;
    margin-bottom: 0.65rem;
    &:before {
      position: absolute;
      @extend .visitheerde-icons;
      @extend .visitheerde-icons-check;
      margin-left: -1.25rem;
      color: $primary;
    }
  }
}

ul.list--inline {
  li {
    display: inline-block;
    margin-bottom: 0;
  }
}

main {
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter article right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;
  margin-bottom: 2rem;

  article {
    grid-column: article;
    -ms-grid-column: 2;
  }
}

.layout-sidebar-right {
  article {
    header {
      grid-column: header;
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
    }

    section {
      grid-column: section;
      -ms-grid-column: 1;
      -ms-grid-row: 2;

      margin-bottom: 2rem;
    }

    aside {
      grid-column: aside;
      -ms-grid-column: 3;
      -ms-grid-row: 2;
    }
  }
}

@include media-breakpoint-up(sm) {
  .sitemap {
    > ul {
      column-count: 2;
      ul {
        margin-top: 1rem;
      }

      li {
        break-inside: avoid;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  main {
    grid-template-columns: 2fr 12fr 2fr;
    -ms-grid-columns: 2fr 12fr 2fr;
    margin-bottom: 4rem;
  }

  .layout-sidebar-right {
    article {
      display: grid;
      grid-template-columns: 8fr 3fr;
     grid-gap: 5rem;
      grid-template-areas:
              "header header"
              "section aside";

      display: -ms-grid;
      -ms-grid-columns: 8fr 5rem 3fr;

      section {
        margin-bottom: 0;
      }
    }
  }

  .layout-small {
    grid-template-columns: 2fr 8fr 2fr;
    -ms-grid-columns: 2fr 8fr 2fr;
  }
}

@include media-breakpoint-up(lg) {
  .layout-small {
    grid-template-columns: 2fr 8fr 2fr;
    -ms-grid-columns: 2fr 8fr 2fr;
  }
}

@include media-breakpoint-up(xl) {
  .layout-small {
    grid-template-columns: 5fr 8fr 5fr;
    -ms-grid-columns: 5fr 8fr 5fr;
  }
  .sitemap {
    > ul {
      column-count: 3;
      ul {
        margin-top: 1rem;
      }

      li {
        break-inside: avoid;
      }
    }
  }
}
@include media-breakpoint-up(xxxl) {
  main {
    grid-template-columns: 3fr 12fr 3fr;
    -ms-grid-columns: 3fr 12fr 3fr;
  }
}


