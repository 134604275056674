.full-image-component {
  margin-bottom: 4rem;
  position: relative;

  &__image {
    .bg-progressive {
      position: relative;
      height: 300px;
    }
  }

  &__overlay {
    width: 100%;
  }

  &__text {
    background-color: $primary;
    padding: 3rem 2rem;

    @include fluid-type($min_width, $max_width, (20px/1.25), 20px, (1.7/1.2), 1.7);
    color: $white;

    a {
      position: relative;
      margin-left: 0.25rem;
      color: $white;
      font-weight: bold;
      text-decoration: none;
      text-transform: uppercase;

      &:after {
        content: "\E001";
        position: absolute;
        top: calc(50% - 5px);
        margin-left: 0.875rem;
        font-family: "visitheerde-icons";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @include fluid-type($min_width, $max_width, (10px/1.25), 10px, 1, 1);
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
        text-decoration: none;
        text-transform: none;
        opacity: 0;
        transition: .35s cubic-bezier(.49,1,.22,1) 0ms;
      }
      &:hover {
        &:after {
          opacity: 1;
          transform: translateX(0.5rem);
        }
      }
    }

    p {
      margin-bottom: 0;
    }

    input[type=text], input[type=number], input[type=password], input[type=email] {
      background-color: #ffffff33;
      color: #ffffff;
      border-bottom: 4px solid #ffffff;

      &::-webkit-input-placeholder { /* Edge */
        color: #ffffff!important;
        font-style: italic!important;
      }

      &::-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #ffffff!important;
        font-style: italic!important;
      }

      &::placeholder {
        color: #ffffff!important;
        font-style: italic!important;
      }
    }
    // checkbox focus
    [type="checkbox"]:checked:focus + label:before,
    [type="checkbox"]:not(:checked):focus + label:before {
      border-color: #ffffff;
    }

    // checkbox hover
    [type="checkbox"]:checked:hover + label,
    [type="checkbox"]:not(:checked):hover + label {
      color: #ffffff;
    }
    // checkbox checked
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
      border-bottom: 4px solid #ffffff;
      border-right: 4px solid #ffffff;
    }
  }

  + footer {
    margin-top: -4rem;
    padding-top: 4rem;
  }
}

@include media-breakpoint-up(lg) {
  .full-image-component {
    &__image {
      .bg-progressive {
        height: 600px;
      }
    }

    &__overlay {
      position: absolute;
      left: 0;
      bottom: 0;
      display:grid;
      grid-template-columns: 7fr 6fr 1fr;

      grid-template-areas:
              "left-gutter full-image-component__text right-gutter";

      display: -ms-grid;
      -ms-grid-columns: 7fr 6fr 1fr;

      &[data-text-position="left"] {
        grid-template-columns: 1fr 6fr 7fr;

        -ms-grid-columns: 1fr 6fr 7fr;
      }
    }
    &__text {
      margin-bottom: -4rem;
      grid-column: full-image-component__text;
      -ms-grid-column: 2;
    }
    + footer {
      padding-top: 8rem;
    }
  }
}

@include media-breakpoint-up(xl) {
  .full-image-component {
    &__image {
      .bg-progressive {
        height: 600px;
      }
    }

    &__overlay {
      grid-template-columns: 9fr 4fr 1fr;

      -ms-grid-columns: 9fr 4fr 1fr;

      &[data-text-position="left"] {
        grid-template-columns: 1fr 4fr 9fr;

        -ms-grid-columns: 1fr 4fr 9fr;
      }
    }
  }
}

@include media-breakpoint-up(xxxl) {
  .full-image-component {
    &__image {
      .bg-progressive {
        height: 650px;
      }
    }

    &__overlay {
      grid-template-columns: 10fr 4fr 2fr;

      -ms-grid-columns: 10fr 4fr 2fr;

      &[data-text-position="left"] {
        grid-template-columns: 2fr 4fr 10fr;

        -ms-grid-columns: 2fr 4fr 10fr;
      }
    }
  }
}