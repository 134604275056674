.categories-component {
  display: grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter categories-component__header right-gutter"
          "left-gutter categories-component__body right-gutter";
  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;
  padding: 2rem 0;

  &__header {
    padding-bottom: 1rem;
    text-align: center;

    grid-area: categories-component__header;
    -ms-grid-column: 2;
    -ms-grid-row: 1;
  }

  &__body {
    grid-area: categories-component__body;
    -ms-grid-column: 2;
    -ms-grid-row: 2;
  }

  &__text {
    grid-area: categories-component__text;
    -ms-grid-column: 1;

    p:first-child {
      @extend .lead;
    }
  }

  &__items {
    grid-area: categories-component__items;
    -ms-grid-column: 3;
    margin-top: 1.875rem;

    .block-component {
      display: flex;
      align-items: flex-end;
      margin-bottom: 1.875rem;
      height: auto !important;
      min-height: 270px;
    }
  }
}

@include media-breakpoint-up(md) {
  .categories-component {
    padding: 3rem 0;
    &__body {
      display: grid;
      grid-template-columns: 4fr 10fr;
      grid-template-areas: "categories-component__text categories-component__items";

      display: -ms-grid;

      grid-gap: 4rem;

      -ms-grid-columns: 4fr 4rem 10fr;
    }
    &__items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1.875rem;

      display: -ms-grid;
      -ms-grid-columns: 3fr 1.875rem 3fr;
      -ms-grid-rows: 3fr 1.875rem 3fr 1.875rem 3fr;

      margin-top: 0;
      margin-bottom: 1.75rem;

      .block-component {
        min-height: 200px;
        margin-bottom: 0;

        &:nth-child(1) {
          -ms-grid-column: 1;
          -ms-grid-row: 1;
        }

        &:nth-child(2) {
          -ms-grid-column: 3;
          -ms-grid-row: 1;
        }

        &:nth-child(3) {
          -ms-grid-column: 1;
          -ms-grid-row: 3;
        }

        &:nth-child(4) {
          -ms-grid-column: 3;
          -ms-grid-row: 3;
        }

        &:nth-child(5) {
          -ms-grid-column: 1;
          -ms-grid-row: 5;
        }

        &:nth-child(6) {
          -ms-grid-column: 3;
          -ms-grid-row: 5;
        }
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .categories-component {
    padding: 5rem 0;
    &__items {
      display: grid;
      grid-template-columns: 3fr 3fr 3fr;
      grid-gap: 1.875rem;

      display: -ms-grid;
      -ms-grid-columns: 3fr 1.875rem 3fr 1.875rem 3fr;
      -ms-grid-rows: 3fr 1.875rem 3fr;

      .block-component {
        min-height: 12vw;

        &:nth-child(1) {
          -ms-grid-column: 1;
          -ms-grid-row: 1;
        }

        &:nth-child(2) {
          -ms-grid-column: 3;
          -ms-grid-row: 1;
        }

        &:nth-child(3) {
          -ms-grid-column: 5;
          -ms-grid-row: 1;
        }

        &:nth-child(4) {
          -ms-grid-column: 1;
          -ms-grid-row: 3;
        }

        &:nth-child(5) {
          -ms-grid-column: 3;
          -ms-grid-row: 3;
        }

        &:nth-child(6) {
          -ms-grid-column: 5;
          -ms-grid-row: 3;
        }
      }
    }
  }
}

@include media-breakpoint-up(xxxl) {
  .categories-component {
    grid-template-columns: 2fr 12fr 2fr;
    -ms-grid-columns: 2fr 12fr 2fr;
    padding: 7rem 0;
  }
}