.topbar {
  display:grid;
  grid-template-columns: 0 12fr 0;
  grid-template-areas:
          "left-gutter topbar-wrapper right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 0 12fr 0;

  max-height: 68px;

  border-bottom: 1px solid;
  transition: 0.3s;

  .phone-number {
    display: flex;
    align-items: center;
    grid-area: phone;
    -ms-grid-column: 1;
    padding: 0 2rem;
    span {
      margin-right: 10px;
      @include fluid-type($min_width, $max_width, (40px/1.125), 40px, (1.2/1.125), 1.2);
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}

.topbar-wrapper {
  grid-column: topbar-wrapper;
  -ms-grid-column: 2;

  display: grid;
  grid-template-areas: "phone topnav";

  display: -ms-grid;
  -ms-grid-columns: 6fr 6fr;

  @include fluid-type($min_width, $max_width, (18px/1.125), 18px, (1.2/1.125), 1.2);
}

ul.nav-topbar {
  padding: 0;
  margin: 0;

  grid-area: topnav;
  -ms-grid-column: 2;
  display: none;

  align-items: center;
  justify-content: flex-end;

  li {
    display: inline-block;
    padding: 0 0.5rem;
    a {
      position: relative;
      display: inline-block;
      padding: 0.5rem 0.6rem;
      text-decoration: none;
      color: $body-color;
    }

    &.highlighted {
      a {
        color: $primary;
      }
    }

    &.active {
      a {
        color: $grey-light;
      }
    }

    &:hover {
      a {
        &:hover {
          //color: $green-dark;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .topbar {
    .container-fluid {
      padding-right: 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .topbar {

  }
}

@include media-breakpoint-up(lg) {
  .topbar {
    height: 50px;
  }

}

@include media-breakpoint-up(xl) {
  .topbar-wrapper {
    justify-content: normal;
    -ms-grid-columns: 6fr 6fr;
  }
  .topbar {
    grid-template-columns: 2fr 12fr 2fr;
    -ms-grid-columns: 2fr 12fr 2fr;
    .phone-number {
      padding: 0;
    }
  }

  ul.nav-topbar {
    display: flex;
  }
}