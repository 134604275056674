.search-component {
  grid-column: searchbox;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: center;
  left: 0;
  padding-top: 30px;
  transition: .35s cubic-bezier(.49,1,.22,1) .35ms;
  z-index: -1;
  opacity: 0;
  visibility: hidden;

  form {
    width: calc(100% - 2rem);
  }

  &__header {
    margin-bottom: 1rem;
    color: $white;
    text-align: center;
  }

  &__body {
    display: flex;
  }

  &__input {
    border: 1px solid #fff!important;
    padding-left: 15px;
    padding-right: 45px;
    height: 60px;
    width: 100%;
    outline: 0;
    border-radius: 0;

    @include fluid-type($min_width, $max_width, (24px/1.6), 24px, 0.9, 0.9);
  }

  &__button {
    width: 90px;
    height: 60px;
    border: 0;
    background-color: $green;
    outline: 0;
    z-index: 2;
    cursor: pointer;

    i {
      display: inline-block;
      height: 60px;
      color: $white;
      text-align: center;
      font-size: 20px;
      line-height: 60px;
    }

    &:hover {
      background-color: darken($green,10%);
    }
  }
}

body.search-component--open {
  overflow: hidden;
  #page-wrapper {
    &:before {
      visibility: visible;
      opacity: 1;
    }
  }
  .search-component {
    visibility: visible;
    opacity: 1;
  }
}

@include media-breakpoint-up(md) {
  .search-component {
    form {
      width: 60%;
    }
  }
}

@include media-breakpoint-up(lg) {
  .search-component {
    form {
      width: 50%;
    }
  }
}

@include media-breakpoint-up(xl) {
  .search-component {
    form {
      width: 40%;
    }
  }
}